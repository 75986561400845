.navbar {
  min-height: 100vh;

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6rem;

    h4 {
      color: var(--color-dark);
      margin-top: 1rem;
    }
  }

  nav ul {
    li {
      margin-left: 20px;
      padding: 2rem;
      position: relative;
      list-style-type: none;
      a {
        display: block;
        width: 100%;
        color: black;
      }
    }
  }
}

.active {
  cursor: pointer;
}
