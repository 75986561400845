.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 40rem;
    padding: 1.5rem;
    h2 {
      color: var(--color-dark);
      text-align: center;
      margin-bottom: 3rem;
    }
    form {
      input[type="text"],
      input[type="email"],
      select,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
      .links {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
      }

      p {
        text-align: center;
        margin: 1rem;
      }
    }
    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
  }

  a {
    margin-left: 0.5rem;
    color: #777;
  }
}
