.info-box {
  display: flex;
  flex-wrap: wrap;
}

.card {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f5f6fa;
  text-align: center;
}
